import React from 'react';
import {Viewer,UrlTemplateImageryProvider,Credit,KmlDataSource,HeadingPitchRange,Rectangle,Clock,JulianDate, Cartesian3} from '../../node_modules/cesium';
import 'cesium/Source/Widgets/widgets.css';
import CesiumNavigation from "cesium-navigation-es6";

class Example extends React.PureComponent {
  divRef = React.createRef();
  viewer = null;
  entity = null;

  render() {
    return (
      <div ref={this.divRef} />
    );
  }

  componentDidMount() {
    this.viewer = new Viewer(this.divRef.current,
      {
      imageryProvider: 
      new UrlTemplateImageryProvider({
        url: 'https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg',
        credit: new Credit('地理院タイル', '', 'https://maps.gsi.go.jp/development/ichiran.html')
      }),
      baseLayerPicker: false,
      geocoder:false,
      timeline : true,
      animation : true,
      FullscreenButton: true,
      homeButton: true,
      vrButton: true,
      sceneModePicker:true,
      navigationHelpButton:true,
      clock: new Clock(),

      });
    this.entity = this.viewer.entities.add({
      name: "Tokyo",
      position: Cartesian3.fromDegrees(139.767052, 35.681167, 100)
    });

    var options = {
      camera: this.viewer.scene.camera,
      canvas: this.viewer.scene.canvas,
    };

    var dataSourcePromise = this.viewer.dataSources.add(KmlDataSource.load("/sample.kml",options));
    // var dataSourcePromise = this.viewer.dataSources.add(KmlDataSource.load(this.props.location.state.dir+this.props.location.state.file,options));

    this.viewer.zoomTo(dataSourcePromise, new HeadingPitchRange(0, -1, 50000));
  }


  componentWillUnmount() {
    this.viewer.destroy();
  }
}

export default Example




// class CesiumContainer extends React.Component {
//   componentDidMount() {
//     var viewer = new Viewer('cesiumContainer', {
//       imageryProvider: 
//       new UrlTemplateImageryProvider({
//         url: 'https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg',
//         // url: 'https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png',
//   //      url: 'https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/',fileExtension: 'jpg',
//         credit: new Credit('地理院タイル', '', 'https://maps.gsi.go.jp/development/ichiran.html')
//       }),
//       baseLayerPicker: false,
//       geocoder:false,
//       timeline : true,
//       animation : true,
//       FullscreenButton: true,
//       homeButton: true,
//       vrButton: true,
//       sceneModePicker:true,
//       navigationHelpButton:true,
//       clock: new Clock(),
//     });


//     // var scene = viewer.scene;
//     // scene.globe.enableLighting = true;
//     // scene.globe.scene3DOnly = true;
//     // scene.shadows = true;


//     var options = {
//       camera: viewer.scene.camera,
//       canvas: viewer.scene.canvas,
//     };

//     var dataSourcePromise = viewer.dataSources.add(KmlDataSource.load(this.props.location.state.dir+this.props.location.state.file,options));

//     viewer.zoomTo(dataSourcePromise, new HeadingPitchRange(0, -1, 50000));
//     viewer._cesiumWidget._creditContainer.style.display = "none";


//   var currentTime = new JulianDate.fromIso8601("1990-07-11T16:00:00Z");
//   viewer.clock.currentTime = currentTime;


//     var navoptions = {};
// navoptions.defaultResetView = Rectangle.fromDegrees(122, 20, 153, 45);
// navoptions.enableCompass = true;
// navoptions.enableZoomControls = true;
// navoptions.enableDistanceLegend = true;
// navoptions.enableCompassOuterRing = true;

// CesiumNavigation(viewer, navoptions);



//   }
 
//   render() {
//     return <div>
//       <div id="cesiumContainer" />
//       <div id="toolbar" />
//       </div>;
//   }
// }

// export default CesiumContainer

